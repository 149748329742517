export const comments = {
  ja: {
    score: {
      up: `生活習慣スコアは5月より%d点上がりました。ジメジメした梅雨の時期も上手く乗り切れていますね。<br>
      7月からTeam 10 minutesの火曜日が「<b>仕事の合間にリフレッシュ</b>」になります。<br>
      ストレッチやセルフマッサージをして、ちょっと一息つきませんか。<br>
      LIVEは<b>火曜日15時</b>から！<a href="https://go.andwell.jp/events">こちら</a>から参加できます♪`,
      down: `生活習慣スコアは5月より%d点下がりました。分類別評価でどの項目に変化があったのか見てみましょう。<br>
      7月からTeam 10 minutesの火曜日が「<b>仕事の合間にリフレッシュ</b>」になります。<br>
      ストレッチやセルフマッサージをして、ちょっと一息つきませんか。<br>
      LIVEは<b>火曜日15時</b>から！<a href="https://go.andwell.jp/events">こちら</a>から参加できます♪`,
      equal100: `生活習慣スコアは5月に引き続き<span class="good">満点</span>です。ジメジメした梅雨の時期に大変素晴らしいです！<br>
      7月からTeam 10 minutesの火曜日が「<b>仕事の合間にリフレッシュ</b>」になります。<br>
      ストレッチやセルフマッサージをして、ちょっと一息つきませんか。<br>
      LIVEは<b>火曜日15時</b>から！<a href="https://go.andwell.jp/events">こちら</a>から参加できます♪`,
      equal: `生活習慣スコアは5月と同じ点数です。分類別評価で、項目別に振り返ってみましょう。<br>
      7月からTeam 10 minutesの火曜日が「<b>仕事の合間にリフレッシュ</b>」になります。<br>
      ストレッチやセルフマッサージをして、ちょっと一息つきませんか。<br>
      LIVEは<b>火曜日15時</b>から！<a href="https://go.andwell.jp/events">こちら</a>から参加できます♪`,
    },
    steps: {
      up: `歩数スコアは5月より%d点上がりました。<br>
      雨の多い時期も歩数を増やせていますね。素晴らしいです！<br>
      良い習慣は上手く継続していきましょう。`,
      down: `歩数スコアは5月より%d点下がりました。<br>
      階段を使うようにする、スーパーや駅に行くときに少しだけ遠回りをするなど、ちょっと意識するだけでも歩数を増やすことができますよ。`,
      equal: `歩数スコアは5月と同じです。<br>
      階段を使うようにする、スーパーや駅に行くときに少しだけ遠回りをするなど、ちょっと意識するだけでも歩数を増やすことができますよ。`,
    },
    exercise: {
      up: `運動のスコアは5月より%d点上がりました。<br>
      今より10分間多く体を動かすことを1年間続けると、<b>1.5～2㎏の減量効果</b>が期待できます。10分間の運動動画は<a href="https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062">こちら</a>！`,
      down: `運動のスコアは5月より%d点下がりました。<br>
      今より10分間多く体を動かすことを1年間続けると、<b>1.5～2㎏の減量効果</b>が期待できます。10分間の運動動画は<a href="https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062">こちら</a>！`,
      equal: `運動のスコアは5月と同じです。<br>
      今より10分間多く体を動かすことを1年間続けると、<b>1.5～2㎏の減量効果</b>が期待できます。10分間の運動動画は<a href="https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062">こちら</a>！`,
    },
    meal: {
      up: `食事のスコアは5月より%d点上がりました。<br>
      食事の時、一口<b>30回以上噛む</b>ことが理想です。あなたは何回噛んでいますか？少ない人は、まずは今より5回増やしてみましょう。`,
      down: `食事のスコアは5月より%d点下がりました。<br>
      食事の時、一口<b>30回以上噛む</b>ことが理想です。あなたは何回噛んでいますか？少ない人は、まずは今より5回増やしてみましょう。`,
      equal: `食事のスコアは5月と同じです。<br>
      食事の時、一口<b>30回以上噛む</b>ことが理想です。あなたは何回噛んでいますか？少ない人は、まずは今より5回増やしてみましょう。`,
    },
    drinking: {
      up: `飲酒のスコアは5月より%d点上がりました。<br>
      そろそろビールが美味しくなる季節ですね。ビールは1日、中ビン1本が適量と言われています。<br>
      他の種類のお酒の適量は<a href="https://go.andwell.jp/library/3dcf7416-dd5f-11ec-b342-06d158a16ffc">コラム</a>で確認！`,
      down: `飲酒のスコアは5月より%d点下がりました。<br>
      そろそろビールが美味しくなる季節ですね。ビールは1日、中ビン1本が適量と言われています。<br>
      他の種類のお酒の適量は<a href="https://go.andwell.jp/library/3dcf7416-dd5f-11ec-b342-06d158a16ffc">コラム</a>で確認！`,
      equal: `飲酒のスコアは5月と同じです。<br>
      そろそろビールが美味しくなる季節ですね。ビールは1日、中ビン1本が適量と言われています。<br>
      他の種類のお酒の適量は<a href="https://go.andwell.jp/library/3dcf7416-dd5f-11ec-b342-06d158a16ffc">コラム</a>で確認！`,
    },
    sleep: {
      up: `睡眠のスコアは5月より%d点上がりました。<br>
      質の良い睡眠をとると、仕事中の作業能力や集中力UPに繋がります。引き続き、この調子で！`,
      down: `睡眠のスコアは5月より%d点下がりました。<br>
      日中の眠気対策には、脳や体を覚醒させてくれるストレッチがおすすめ！体をひねったり、伸びをしてみましょう。`,
      equal: `睡眠のスコアは5月と同じです。<br>
      日中の眠気対策には、脳や体を覚醒させてくれるストレッチがおすすめ！体をひねったり、伸びをしてみましょう。`,
    },
    stress: {
      up: `ストレスのスコアは5月より%d点上がりました。<br>
      ”笑う”ことで、心身ともにリラックス効果が期待できます。
      笑いヨガの動画は<a href="https://go.andwell.jp/library/58d7bd8d-62bc-11ec-9ce8-0af363eb5062">こちら</a>！口角を上げて、声を出して笑いましょう！`,
      down: `ストレスのスコアは5月より%d点下がりました。<br>
      ”笑う”ことで、心身ともにリラックス効果が期待できます。
      笑いヨガの動画は<a href="https://go.andwell.jp/library/58d7bd8d-62bc-11ec-9ce8-0af363eb5062">こちら</a>！口角を上げて、声を出して笑いましょう！`,
      equal: `ストレスのスコアは5月と同じです。<br>
      ”笑う”ことで、心身ともにリラックス効果が期待できます。
      笑いヨガの動画は<a href="https://go.andwell.jp/library/58d7bd8d-62bc-11ec-9ce8-0af363eb5062">こちら</a>！口角を上げて、声を出して笑いましょう！`,
    },
  },
  en: {
    score: {
      up: `Your lifestyle score has gone up %d pts since May. You're also doing a good job getting through the humid rainy season.<br>
      Starting in July, Team 10 Minutes' "<b>Refresh during Your Spare Moments at Work</b>" will be streamed on Tuesdays.<br>
      Try stretches and a self-massage and give yourself time for a break.<br>
      Streaming live on <b>Tuesdays</b> from <b3:00 p.m.</b>! You can watch it <a href="https://go.andwell.jp/events">here</a>.`,
      down: `Your lifestyle score has gone down %d pts since May. Use the categorized ratings to see what items have changed.<br>
      Starting in July, Team 10 Minutes' "<b>Refresh during Your Spare Moments at Work</b>" will be streamed on Tuesdays.<br>
      Try stretches and a self-massage and give yourself time for a break.<br>
      Streaming live on <b>Tuesdays</b> from <b3:00 p.m.</b>! You can watch it <a href="https://go.andwell.jp/events">here</a>.`,
      equal100: `Your lifestyle score is the <span class="good">maximum</span> again, the same as May. You're doing fantastic during the humid rainy season!<br>
      Starting in July, Team 10 Minutes' "<b>Refresh during Your Spare Moments at Work</b>" will be streamed on Tuesdays.<br>
      Try stretches and a self-massage and give yourself time for a break.<br>
      Streaming live on <b>Tuesdays</b> from <b3:00 p.m.</b>! You can watch it <a href="https://go.andwell.jp/events">here</a>.`,
      equal: `Your lifestyle score is the same as May. Use the categorized ratings to review your progress item by item.<br>
      Starting in July, Team 10 Minutes' "<b>Refresh during Your Spare Moments at Work</b>" will be streamed on Tuesdays.<br>
      Try stretches and a self-massage and give yourself time for a break.<br>
      Streaming live on <b>Tuesdays</b> from <b3:00 p.m.</b>! You can watch it <a href="https://go.andwell.jp/events">here</a>.`,
    },
    steps: {
      up: `Your steps score has gone up %d pts since May.<br>
      You're getting more steps even during the rainy season. That's fantastic! 
      Keep up the good habits.`,
      down: `Your steps score has gone down %d pts since May. <br>
      You can increase your steps with a bit of conscious effort. For example, use the stairs, or take a short detour when you go to the supermarket or station.`,
      equal: `Your steps score is the same as May. <br>
      You can increase your steps with a bit of conscious effort. For example, use the stairs, or take a short detour when you go to the supermarket or station.`,
    },
    exercise: {
      up: `Your exercise score has gone up %d pts since May.<br>
      If you move your body for 10 more minutes every day for a year, you can lose <b>1.5 to 2 kg</b>. Watch the 10-minute exercise video <a href="https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062">here</a>!`,
      down: `Your exercise score has gone down %d pts since May.<br>
      If you move your body for 10 more minutes every day for a year, you can lose <b>1.5 to 2 kg</b>. Watch the 10-minute exercise video <a href="https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062">here</a>!`,
      equal: `Your exercise score is the same as May.<br>
      If you move your body for 10 more minutes every day for a year, you can lose <b>1.5 to 2 kg</b>. Watch the 10-minute exercise video <a href="https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062">here</a>!`,
    },
    meal: {
      up: `Your food score has gone up %d pts since May.<br>
      Ideally, you <b>should chew at least 30 times per bite</b> when you eat. How many times do you chew? If you don't chew enough, start by chewing 5 more times per bite.`,
      down: `Your food score has gone down %d pts since May.<br>
      Ideally, you <b>should chew at least 30 times per bite</b> when you eat. How many times do you chew? If you don't chew enough, start by chewing 5 more times per bite.`,
      equal: `Your food score is the same as May..<br>
      Ideally, you <b>should chew at least 30 times per bite</b> when you eat. How many times do you chew? If you don't chew enough, start by chewing 5 more times per bite.`,
    },
    drinking: {
      up: `Your alcohol score has gone up %d pts since May. <br>
      It's almost the season when beer tastes good. They say that one medium-sized bottle of beer per day is just enough. <br>
      Check the <a href="https://go.andwell.jp/library/3dcf7416-dd5f-11ec-b342-06d158a16ffc">column</a> for other types of alcohol!`,
      down: `Your alcohol score has gone down %d pts since May. <br>
      It's almost the season when beer tastes good. They say that one medium-sized bottle of beer per day is just enough. <br>
      Check the <a href="https://go.andwell.jp/library/3dcf7416-dd5f-11ec-b342-06d158a16ffc">column</a> for other types of alcohol!`,
      equal: `Your alcohol score is the same as May.. <br>
      It's almost the season when beer tastes good. They say that one medium-sized bottle of beer per day is just enough. <br>
      Check the <a href="https://go.andwell.jp/library/3dcf7416-dd5f-11ec-b342-06d158a16ffc">column</a> for other types of alcohol!`,
    },
    sleep: {
      up: `Your sleep score has gone up %d pts since May. <br>
      Getting a good night's sleep can improve your abilities and concentration at work. Keep it up!`,
      down: `Your sleep score has gone down %d pts since May.<br>
      For drowsiness during the day, wake down your mind and body by stretching! Twist and stretch your body.`,
      equal: `Your sleep score is the same as May.<br>
      For drowsiness during the day, wake up your mind and body by stretching! Twist and stretch your body.`,
    },
    stress: {
      up: `Your stress score has gone up %d pts since May. <br>
      Laughing can help relax both the body and mind.  <br>
      Watch the laughing yoga video <a href="https://go.andwell.jp/library/58d7bd8d-62bc-11ec-9ce8-0af363eb5062">here</a>! Open your mouth, raise your voice, and laugh!`,
      down: `Your stress score has gone down %d pts since May. <br>
      Laughing can help relax both the body and mind.  <br>
      Watch the laughing yoga video <a href="https://go.andwell.jp/library/58d7bd8d-62bc-11ec-9ce8-0af363eb5062">here</a>! Open your mouth, raise your voice, and laugh!`,
      equal: `Your stress score is the same as May. <br>
      Laughing can help relax both the body and mind.  <br>
      Watch the laughing yoga video <a href="https://go.andwell.jp/library/58d7bd8d-62bc-11ec-9ce8-0af363eb5062">here</a>! Open your mouth, raise your voice, and laugh!`,
    },
  },
}
